/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const CustomerListResults = ({ id, customers, ...rest }) => {
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [wa, setWa] = useState(false);
  const [mail, setMail] = useState(false);
  const [prov, setProv] = useState(false);
  const [reg, setReg] = useState(false);
  const [wo, setWo] = useState(false);
  const [nwo, setNwo] = useState(false);
  const [two, setTwo] = useState(false);
  /*
  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer._id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };
*/
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleMongo = (c) => {
    fetch(`https://gaapi.zaini.co.id/delcust?id=${c._id}`,)
      .then((res) => {
        if (res.ok) {
          return window.location.reload();
        }
        throw console.log(res);
      });
  };

  /*
  const handleCar = (u, n) => {
    setUid(u);
    setName(n);
  };
  */
  return (
    <Box sx={{ mt: 3 }}>

      <Card {...rest}>
        <div style={{ textAlign: 'end', padding: 20 }}>
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={wa}
                onClick={() => { setWa(!wa); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Whatsapp</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={mail}
                onClick={() => { setMail(!mail); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Email</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={prov}
                onClick={() => { setProv(!prov); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Provinsi</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={reg}
                onClick={() => { setReg(!reg); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Tanggal Registrasi</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={wo}
                onClick={() => { setWo(!wo); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Total WO</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={nwo}
                onClick={() => { setNwo(!nwo); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">No WO Terakhir</Typography>}
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={(
              <Switch
                color="primary"
                defaultValue={two}
                onClick={() => { setTwo(!two); }}
              />
)}
            label={<Typography variant="body2" color="textSecondary">Tgl WO Terakhir</Typography>}
            labelPlacement="end"
          />
        </div>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>

            <Table size="small" aria-label="a dense table">

              <TableHead>

                <TableRow>
                  <TableCell padding="checkbox">
                    <Box sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                    >
                      {/*
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                      onChange={handleSelectAll}
                    />
                  */}
                    </Box>
                  </TableCell>
                  <TableCell>
                    Nama
                  </TableCell>
                  <TableCell>
                    Hp
                  </TableCell>
                  {wa
                  && (
                  <TableCell>
                    WA
                  </TableCell>
                  )}
                  {mail
                  && (
                  <TableCell>
                    Email
                  </TableCell>
                  )}
                  <TableCell>
                    Alamat
                  </TableCell>
                  <TableCell>
                    Kelurahan
                  </TableCell>
                  <TableCell>
                    Kecamatan
                  </TableCell>
                  <TableCell>
                    Kabupaten
                  </TableCell>
                  {prov
                  && (
                  <TableCell>
                    Provinsi
                  </TableCell>
                  )}
                  {reg
                  && (
                  <TableCell>
                    Tgl Registrasi
                  </TableCell>
                  )}
                  {wo
                  && (
                  <TableCell>
                    Total WO
                  </TableCell>
                  )}
                  {nwo
                  && (
                  <TableCell>
                    No WO Terakhir
                  </TableCell>
                  )}
                  {two
                  && (
                  <TableCell>
                    Tgl WO Terakhir
                  </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.slice(0, limit).map((customer) => (
                  <TableRow
                    hover
                    key={customer._id}
                  >
                    <TableCell padding="checkbox">
                      <Box sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                      >
                        <IconButton
                          aria-label="more"
                          color="primary"
                          aria-controls="customer car"
                          onClick={() => id(customer)}
                        >
                          <DirectionsCarIcon />
                        </IconButton>
                        <IconButton
                          aria-label="more"
                          aria-controls="edit customer"
                          onClick={null}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="more"
                          aria-controls="delete customer"
                          aria-haspopup="true"
                          color="secondary"
                          onClick={() => { handleMongo(customer); }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                        {/*
                      <Checkbox
                        checked={selectedCustomerIds.indexOf(customer._id) !== -1}
                        onChange={(event) => handleSelectOne(event, customer._id)}
                        value="true"
                      />
                      */}

                      </Box>
                    </TableCell>

                    <TableCell>

                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={customer.avatarUrl}
                          sx={{ mr: 2 }}
                        >
                          {getInitials(customer.name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {customer.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {customer.phone}
                    </TableCell>
                    {wa
                  && (
                  <TableCell>
                    {customer.wa}
                  </TableCell>
                  )}
                    {mail
                  && (
                  <TableCell>
                    {customer.email}
                  </TableCell>
                  )}
                    <TableCell>
                      {customer.addr}
                    </TableCell>
                    <TableCell>
                      {customer.kel}
                    </TableCell>
                    <TableCell>
                      {customer.kec}
                    </TableCell>
                    <TableCell>
                      {customer.kab}
                    </TableCell>
                    {prov
                  && (
                  <TableCell>
                    {customer.prov}
                  </TableCell>
                  )}
                    {reg
                  && (
                  <TableCell>
                    {moment(customer.reg).format('DD/MM/YYYY')}
                  </TableCell>
                  )}
                    {wo
                  && (
                  <TableCell>
                    {customer.ttlwo}
                  </TableCell>
                  )}
                    {nwo
                  && (
                  <TableCell>
                    {customer.lastwo}
                  </TableCell>
                  )}
                    {two
                  && (
                  <TableCell>
                    {moment(customer.lastt).format('DD/MM/YYYY')}
                  </TableCell>
                  )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </Box>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired,
  id: PropTypes.object.isRequired
};

export default CustomerListResults;
