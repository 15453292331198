import {
  Box, Card, CardHeader, Typography, CardContent
} from '@material-ui/core';

const Carstat = () => (
  <Box>
    <Card>
      <CardHeader title="Brand & Type Mobil" />
      <CardContent>
        <Typography>
          Mobil berdasarkan
        </Typography>
      </CardContent>
    </Card>
  </Box>
);
export default Carstat;
