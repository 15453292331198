import {
  Box,
  // Container,
  // Grid
} from '@material-ui/core';

const Aplikasi = () => (
  <Box />
);

export default Aplikasi;
