import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@material-ui/core';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import HomeIcon from '@material-ui/icons/Home';
import SaveIcon from '@material-ui/icons/Save';
import provinsi from 'src/components/customer/Provinsi';
import { capitalCase } from 'change-case';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

const CustomerListToolbar = (props) => {
  const blueHead = {
    backgroundColor: '#576675',
    color: '#fff'
  };
  const [prov, setProv] = useState({ id: '', nama: '' });
  const [kab, setKab] = useState({ id: '', nama: '' });
  const [kec, setKec] = useState({ id: '', nama: '' });
  const [kel, setKel] = useState({ id: '', nama: '' });
  const [kabl, setKabl] = useState([]);
  const [kecl, setKecl] = useState([]);
  const [kell, setKell] = useState([]);

  const [provv, setProvv] = useState('');
  const [kabv, setKabv] = useState('');
  const [kecv, setKecv] = useState('');
  const [kelv, setKelv] = useState('');
  const [rumv, setRumv] = useState('');

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [wa, setWa] = useState('');

  const [zam, setZam] = useState('ZAW Surakarta');
  const [loc, setLoc] = useState('');

  const reg = new Date();
  console.log(provv, kabv, kecv, kelv);
  useEffect(() => {
    if (prov.id === '') { console.log(); } else {
      const up = `https://ibnux.github.io/data-indonesia/kabupaten/${prov.id}.json`;
      fetch(up)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((d) => {
          setKabl(d);
        });
    }
  }, [prov]);
  useEffect(() => {
    if (kab.id === '') { console.log(); } else {
      const ukab = `https://ibnux.github.io/data-indonesia/kecamatan/${kab.id}.json`;
      fetch(ukab)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((d) => {
          setKecl(d);
        });
    }
  }, [kab]);

  useEffect(() => {
    if (kec.id === '') { console.log(); } else {
      const ukec = `https://ibnux.github.io/data-indonesia/kelurahan/${kec.id}.json`;
      fetch(ukec)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((d) => {
          setKell(d);
        });
    }
  }, [kec]);

  const handleZam = (event) => {
    setZam(event.target.value);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleWa = (event) => {
    setWa(event.target.value);
  };

  const handleProv = (event) => {
    setProv(event.target.value);
    setProvv(capitalCase(event.target.value.nama));
  };

  const handleKab = (event) => {
    setKab(event.target.value);
    setKabv(capitalCase(event.target.value.nama));
  };
  const handleKec = (event) => {
    setKec(event.target.value);
    setKecv(event.target.value.nama);
  };
  const handleKel = (event) => {
    setKel(event.target.value);
    setKelv(event.target.value.nama);
  };

  const handleRum = (event) => {
    setRumv(event.target.value);
  };

  async function handleMongo() {
    const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        zam, name, email, phone, wa, reg, addr: rumv, kel: kelv, kec: kecv, kab: kabv, prov: provv, loc
      })
    };
    await fetch('https://gaapi.zaini.co.id/ccust', requestOptions)
      .then((res) => {
        if (res.ok) {
          return window.location.reload();
        }
        throw console.log(res);
      });
  }

  const getloc = () => {
    const a = encodeURI(rumv + kelv + kecv + kabv + provv);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyA-7aO1IaGzrTOLqgruWLYfnfKvlPDb3_k&address=${a}`;
    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((d) => {
        setLoc(d.results[0].geometry.location);
        console.log(d.results[0].geometry.location);
      });
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }} />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Card>
            <CardHeader title="ZAM" avatar={<EmojiTransportationIcon />} style={blueHead} />
            <CardContent>
              <FormControl fullWidth>
                <InputLabel id="branch">Cabang</InputLabel>
                <Select
                  labelId="branch"
                  id="branch"
                  value={zam}
                  onChange={handleZam}
                >
                  <MenuItem value="ZAW Surakarta">ZAW Surakarta</MenuItem>
                  <MenuItem value="ZAR Surakarta">ZAR Surakarta</MenuItem>
                  <MenuItem value="BMP Semarang">BMP Semarang</MenuItem>
                  <MenuItem value="BMP Yogyakarta">BMP Yogyakarta</MenuItem>

                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Card>
            <CardHeader title="Detail Customer" avatar={<RecentActorsIcon />} style={blueHead} />
            <CardContent>
              <TextField
                fullWidth
                style={{ padding: 10 }}
                label="Nama"
                variant="outlined"
                value={name}
                onChange={handleName}
                size="small"
              />
              <TextField
                fullWidth
                style={{ padding: 10 }}
                label="No HP"
                variant="outlined"
                value={phone}
                onChange={handlePhone}
                size="small"
              />
              <TextField
                fullWidth
                style={{ padding: 10 }}
                label="WA"
                variant="outlined"
                value={wa}
                onChange={handleWa}
                size="small"
              />
              <TextField
                fullWidth
                style={{ padding: 10 }}
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmail}
                size="small"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Card>
            <CardHeader title="Alamat Customer" avatar={<HomeIcon />} style={blueHead} />
            <CardContent>
              <FormControl fullWidth>
                <InputLabel id="prov">Provinsi</InputLabel>
                <Select
                  labelId="prov"
                  id="prov"
                  value={prov}
                  onChange={handleProv}
                >
                  {provinsi.map((p) => <MenuItem key={p.id} value={p}>{p.nama}</MenuItem>)}

                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="kab">Kota / Kabupaten</InputLabel>
                <Select
                  labelId="kab"
                  id="kab"
                  value={kab}
                  onChange={handleKab}
                >
                  {kabl.map((p) => <MenuItem key={p.id} value={p}>{p.nama}</MenuItem>)}

                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="kec">Kecamatan</InputLabel>
                <Select
                  labelId="kec"
                  id="kec"
                  value={kec}
                  onChange={handleKec}
                >
                  {kecl.map((p) => <MenuItem key={p.id} value={p}>{p.nama}</MenuItem>)}

                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="kel">Kelurahan</InputLabel>
                <Select
                  labelId="kel"
                  id="kel"
                  value={kel}
                  onChange={handleKel}
                >
                  {kell.map((p) => <MenuItem key={p.id} value={p}>{p.nama}</MenuItem>)}

                </Select>
              </FormControl>
              <TextField
                fullWidth
                style={{ marginTop: 15 }}
                label="No Rumah / RT RW"
                variant="outlined"
                value={rumv}
                onChange={(event) => { handleRum(event); }}
                size="small"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          {loc === ''
            ? (
              <Button
                style={{ backgroundColor: 'green' }}
                variant="contained"
                startIcon={<PersonPinCircleIcon />}
                onClick={() => { getloc(); }}
              >
                Dapatkan Lokasi
              </Button>
            )
            : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={handleMongo}
              >
                Simpan Customer
              </Button>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerListToolbar;
