import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import LineIcon from 'react-lineicons';

const Fbzaw = (props) => {
  const [zaw0, setZaw0] = useState(0);
  const [zaw1, setZaw1] = useState(0);
  const [zaw2, setZaw2] = useState(0);
  const [zaw3, setZaw3] = useState(0);
  const [zaw4, setZaw4] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const url = 'https://graph.facebook.com/v11.0/17841403026252367/insights?metric=impressions,reach,profile_views,website_clicks,phone_call_clicks&period=day&access_token=EAAGrfOLgImoBAGAPKZAynau9yMra4WIBGNwZBRh6VfFrEwfHcre9pRiSOV4obnsg2R2fR6ZBrHfoiZAMIxmtrF77a9PYK7ZBUHVB9CZAkwU6r82oW9rhKkhsAzZCNvHjAJUkZCHs0NLg53ydGPhCmVfrTWTAfiIMuDBzTXZBL53TqTUASfXCLEYLvKqIqvjy1oezIU3vFC7yQgAZDZD';

    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((d) => {
        setZaw0(d.data[0].values[0].value);
        setZaw1(d.data[1].values[0].value);
        setZaw2(d.data[2].values[0].value);
        setZaw3(d.data[3].values[0].value);
        setZaw4(d.data[4].values[0].value);
        // console.log(data);
      });
  }, []);

  const data = {
    datasets: [
      {
        data: [zaw0, zaw1, zaw2, zaw3, zaw4],
        backgroundColor: [
          colors.purple[500],
          colors.red[600],
          colors.orange[600],
          colors.blue[600],
          colors.green[400]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Impressions', 'reach', 'Profile views', 'Website clicks', 'Phone call clicks']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const devices = [
    {
      title: 'Impress',
      value: zaw0,
      color: colors.purple[500]
    },
    {
      title: 'Reach',
      value: zaw1,
      color: colors.red[600]
    },
    {
      title: 'Views',
      value: zaw2,
      color: colors.orange[600]
    },
    {
      title: 'Clicks',
      value: zaw3,
      color: colors.blue[600]
    },
    {
      title: 'Call',
      value: zaw4,
      color: colors.green[400]
    }
  ];

  return (
    <Card {...props}>
      <CardHeader
        avatar={<LineIcon name="facebook-original" />}
        title="Facebook ZAW"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 200,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            title,
            value
          }) => ((
            <Box
              key={color + title}
              sx={{
                p: 0.3,
                textAlign: 'center'
              }}
            >

              <Typography
                color="textSecondary"
                variant="caption"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h3"
              >
                {value}
              </Typography>
            </Box>
          )
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Fbzaw;
