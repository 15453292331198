import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import WebDeviceVisitor from 'src/components/dashboard//WebDeviceVisitor';
import WebCityVisitor from 'src/components/dashboard//WebCityVisitor';
import WebSource from 'src/components/dashboard//WebSource';
import Instazaw from 'src/components/dashboard//Instazaw';
import Instazar from 'src/components/dashboard//Instazar';
import Fbzaw from 'src/components/dashboard//Fbzaw';
import Fbzar from 'src/components/dashboard//Fbzar';

const Dashboard = () => (
  <>
    <Helmet>
      <title>Zaini Auto Motor</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Instazaw />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Instazar />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Fbzaw />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Fbzar />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <WebCityVisitor />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <WebSource />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <WebDeviceVisitor sx={{ height: '100%' }} />
          </Grid>

        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
