/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Grid,
  CardContent,
  CardHeader,
  Avatar,
  CardMedia,
  Typography,
  IconButton,
  Button,
  Box,
  CircularProgress
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const CustomerCar = ({ uid, name }) => {
  const [cars, setCars] = useState([]);
  const [isLoaded, setIsLoaded] = useState();

  useEffect(() => {
    const url = `https://gaapi.zaini.co.id/custcar?u=${uid}`;
    setIsLoaded(false);
    fetch(url)
      .then((res) => {
        if (res.ok) {
          setIsLoaded(true);
          return res.json();
        }
        setIsLoaded(true);
        throw res;
      })
      .then((d) => {
        setCars(d);
      });
  }, [uid]);
  if (isLoaded === false) { return <CircularProgress style={{ margin: 50 }} />; }
  if (isLoaded === true) {
    return (
      <Box style={{ backgroundColor: '#e3e3e3', padding: '20px' }}>
        <Typography variant="h4" component="h4">
          {name}
        </Typography>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            sm={12}
            xl={12}
            xs={12}
          />
          {cars.map((c) => (
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Card>
                <CardHeader
                  avatar={(
                    <Avatar
                      src={`https://www.carlogos.org/car-logos/${c.make.toLowerCase()}-logo.png`}
                      aria-label={c.make}
                    >
                      R
                    </Avatar>
            )}
                  title={c.make}
                  subheader={c.model}
                  action={(
                    <IconButton aria-label="delete" color="secondary">
                      <DeleteForeverIcon />
                    </IconButton>
              )}
                />
                <CardContent>
                  <CardMedia
                    style={{
                      height: 0,
                      marginBottom: 10,
                      paddingTop: '56.25%',
                    }}
                    image={`https://databases.one/cardatabase-demo-advanced/img/${c.img}.png`}
                    title={c.img}
                  />
                  <Typography variant="h4" component="h2" style={{ color: '#1a73e8' }}>
                    {c.plate}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="h5">
                    {c.vin}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Tahun :
                    {' '}
                    {c.year}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Body :
                    {' '}
                    {c.body}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Trim :
                    {' '}
                    {c.trim}
                  </Typography>
                  <Box textAlign="end">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PostAddIcon />}
                    >
                      Buat WO
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
          {isLoaded && (
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Card style={{ height: '100%', minHeight: 100 }}>
              <Box style={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '200',
                flexDirection: 'column'
              }}
              >
                <Button
                  style={{ marginTop: 30 }}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah Mobil
                </Button>

              </Box>
            </Card>
          </Grid>
          )}
        </Grid>
      </Box>
    );
  }
  return <Box />;
};

CustomerCar.propTypes = {
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default CustomerCar;
