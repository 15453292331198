/* eslint-disable camelcase */
import React, { Fragment, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const { JS_NumberFormat } = require('js-number-formatter');

const {
  Box, Card, Grid, Typography, Button, Container,
} = require('@material-ui/core');

const Workorder = () => {
  const [inputFields, setInputFields] = useState([
    {
      labour: '', qty: 0, cost: 0, disc: 0, subttl: 0
    }
  ]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === 'labour') {
      values[index].labour = event.target.value;
    } else if (event.target.name === 'qty') {
      values[index].qty = event.target.value;
    } else if (event.target.name === 'cost') {
      values[index].cost = event.target.value;
    } else {
      values[index].disc = event.target.value;
    }
    const t = values[index].qty * values[index].cost;
    const disc = t * (values[index].disc / 100);
    values[index].subttl = disc === 0 ? t : t - disc;
    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      labour: '', qty: 0, cost: 0, disc: 0, subttl: 0
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Button
        style={{ marginLeft: 60 }}
        color="primary"
        variant="contained"
        onClick={handlePrint}
        startIcon={<PrintIcon />}
      >
        Cetak
      </Button>
      <PerfectScrollbar>
        <style>
          {'@media print {.hide{display: none;}}'}
        </style>
        <Card style={{ width: 700, minHeight: 1000, margin: 50 }}>
          <Container ref={componentRef}>
            <title>WO-XXXXXXX</title>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ paddingTop: 60, paddingLeft: 40 }}>
                <img src="/static/zam.png" alt="zam" width="150" />
                <br />
                <Typography variant="caption" style={{ fontSize: '0.6rem' }}>
                  Palur wetan, RT3 RW5, Palur,
                  <br />
                  Mojolaban, Sukoharjo, Jawa Tengah. 57554
                  <br />
                  CS: 08112959043 / 088802632214
                  <br />
                  SA: 081548519005

                </Typography>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: 60, paddingRight: 30 }} justifyContent="flex-end">
                <Typography align="right" variant="h3" component="h1">
                  Work Order
                </Typography>
                <Typography align="right" variant="subtitle1">WO-384374666</Typography>
                <Typography align="right" variant="subtitle2">Customer: Bpk/Ibu XXXXX</Typography>
              </Grid>
            </Grid>
            <Grid>
              <Box style={{ paddingTop: 50 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="0">
                        <Typography variant="body1">Labour</Typography>
                      </TableCell>
                      <TableCell padding="0">
                        <Typography variant="body1">Qty</Typography>
                      </TableCell>
                      <TableCell padding="0">
                        <Typography variant="body1">Cost</Typography>
                      </TableCell>
                      <TableCell padding="0">
                        <Typography variant="body1">Disc %</Typography>
                      </TableCell>
                      <TableCell padding="0">
                        <Typography variant="body1">Sub Total</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inputFields.map((inputField, index) => (
                      <Fragment key={`${inputField}`}>
                        <TableRow>
                          <TableCell padding="0">
                            <textarea
                              style={{
                                minWidth: 300, padding: 0, fontSize: 'smaller', border: 'none'
                              }}
                              inputProps={{ style: { fontSize: 'smaller' } }}
                              onChange={(event) => handleInputChange(index, event)}
                              id="labour"
                              name="labour"
                              value={inputField.labour}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              style={{
                                padding: 0, fontSize: 'smaller', border: 'none'
                              }}
                              onChange={(event) => handleInputChange(index, event)}
                              id="qty"
                              name="qty"
                              size="3"
                              value={inputField.qty}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              style={{
                                padding: 0, fontSize: 'smaller', border: 'none'
                              }}
                              onChange={(event) => handleInputChange(index, event)}
                              id="cost"
                              name="cost"
                              size="5"
                              value={inputField.cost}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              style={{
                                padding: 0, fontSize: 'smaller', border: 'none'
                              }}
                              onChange={(event) => handleInputChange(index, event)}
                              id="disc"
                              name="disc"
                              size="3"
                              value={inputField.disc}
                            />
                          </TableCell>
                          <TableCell>
                            <input
                              style={{
                                padding: 0, fontSize: 'smaller', border: 'none'
                              }}
                              id="subttl"
                              name="subttl"
                              size="10"
                              value={JS_NumberFormat(inputField.subttl, { op_AllowDecimal: false })}
                            />
                          </TableCell>
                          <TableCell className="hide">
                            <IconButton color="secondary" onClick={() => handleRemoveFields(index)} size="small"><RemoveCircleIcon /></IconButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
                <div className="hide" style={{ float: 'right' }}>
                  <IconButton color="primary" onClick={() => handleAddFields()} size="small"><AddCircleIcon /></IconButton>
                </div>
                {console.log(JSON.stringify(inputFields, null, 2))}
              </Box>
            </Grid>
          </Container>
        </Card>
      </PerfectScrollbar>
    </Box>
  );
};
export default Workorder;
