import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  // colors
} from '@material-ui/core';
import LineIcon from 'react-lineicons';

const WebCityVisitor = (props) => {
  const [city, setCity] = useState([]);
  const [jum, setJum] = useState([]);

  useEffect(() => {
    const url = 'https://us-central1-zaini-57782.cloudfunctions.net/bengkel/gacity';

    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data) => {
        const lc = [];
        const lj = [];
        const d = data.rows;
        for (let i = 0; i < 15; i++) {
          lc.push(d[i].dimensionValues[0].value);
          lj.push(d[i].metricValues[0].value);
        }
        setCity(lc);
        setJum(lj);
      });
  }, []);

  const theme = useTheme();
  function getRandomColor() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function lrc(x) {
    const a = [];
    for (let i = 0; i < x; i++) {
      a.push(getRandomColor());
    }
    return a;
  }

  const data = {
    datasets: [
      {
        backgroundColor: lrc(jum.length),
        data: jum,
      },
    ],
    labels: city
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...props}>
      <CardHeader
        avatar={<LineIcon name="chrome" />}
        title="Kota Pengunjung Web 7D"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      />
    </Card>
  );
};

export default WebCityVisitor;
