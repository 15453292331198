/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import {
  Box,
  Chip
} from '@material-ui/core';

const AnyReactComponent = ({ icon }) => <div>{icon}</div>;

const Cmap = () => {
  const defaultProps = {
    center: {
      lat: -7.459094,
      lng: 110.7581263
    },
    zoom: 9
  };
  const [l, setL] = useState([]);

  useEffect(() => {
    const loc = JSON.parse(window.localStorage.getItem('cust'));
    setL(loc);
  }, []);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyA-7aO1IaGzrTOLqgruWLYfnfKvlPDb3_k' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {l !== []
          ? l.map((i) => (
            <AnyReactComponent
              lat={i.loc.lat}
              lng={i.loc.lng}
              icon={(
                <Box>
                  <PersonPinCircleIcon color="secondary" />
                  <Chip size="small" label={i.name} color="primary" />
                </Box>
)}
            />
          )) : <Box />}
      </GoogleMapReact>
    </div>
  );
};

export default Cmap;
