/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Box,
  Button
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Addcust from './Addcust';

const CustomerListToolbar = ({ newcust }) => {
  const [c, setC] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          defaultValue={c}
          onClick={() => { newcust(); setC(!c); }}
          startIcon={<PersonAddIcon />}
          endIcon={c === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        >
          Tambah Customer
        </Button>
      </Box>
      {c === false ? <Box /> : <Addcust />}
    </Box>
  );
};

export default CustomerListToolbar;
