import {
  Box, Container, Grid, Card, CardHeader
} from '@material-ui/core';
import Cmap from 'src/components/custcar/Cmap';
import Ccount from 'src/components/custcar/Ccount';
import Carstat from 'src/components/custcar/Carstat';
import Caryear from 'src/components/custcar/Caryear';

const Statcustcar = () => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      minHeight: '100%',
      py: 3
    }}
  >
    <Container maxWidth={false}>

      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={8}
          xs={12}
        >
          <Card>
            <CardHeader
              title="Lokasi Customer"
            />
            <Cmap />
          </Card>
        </Grid>

        <Grid
          item
          lg={4}
          md={12}
          xl={4}
          xs={12}
        >
          <Box><Ccount /></Box>
        </Grid>
      </Grid>
    </Container>
    <Container style={{ paddingTop: '20px' }}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={8}
          xs={12}
        >
          <Carstat />
        </Grid>

        <Grid
          item
          lg={4}
          md={12}
          xl={4}
          xs={12}
        >
          <Caryear />
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Statcustcar;
