import { Helmet } from 'react-helmet';
import {
  Box
} from '@material-ui/core';

const Account = () => (
  <>
    <Helmet>
      <title>Account | Material Kit</title>
    </Helmet>
    <Box />
  </>
);

export default Account;
