import {
  Box, Card, CardContent, CardHeader, Typography
} from '@material-ui/core';

const Ccount = () => (
  <Box>
    <Card>
      <CardHeader title="Jumlah Customer" />
      <CardContent>
        <Typography>
          Berdasar Provinsi
        </Typography>
        <Typography>
          Berdasar Kabupaten / Kota
        </Typography>
      </CardContent>
    </Card>

  </Box>
);

export default Ccount;
