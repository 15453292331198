// import { Link as useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
} from '@material-ui/core';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCXVqz7MFZiK4242dhqMgAW-BZ_YA72NTU',
  authDomain: 'zamga-c7886.firebaseapp.com',
  projectId: 'zamga-c7886',
  storageBucket: 'zamga-c7886.appspot.com',
  messagingSenderId: '882971438642',
  appId: '1:882971438642:web:685327d6bf61dce35d162b',
  measurementId: 'G-YTQQN048LD'
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/app/dashboard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
};

const Login = () => (
  <>
    <Helmet>
      <title>Zaini Auto Motor</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Container>
    </Box>
  </>
);

export default Login;
