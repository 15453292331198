import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import {
  ThemeProvider,
  Box,
  Container,
} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import data from './Data';

const firebaseConfig = {
  apiKey: 'AIzaSyCXVqz7MFZiK4242dhqMgAW-BZ_YA72NTU',
  authDomain: 'zamga-c7886.firebaseapp.com',
  projectId: 'zamga-c7886',
  storageBucket: 'zamga-c7886.appspot.com',
  messagingSenderId: '882971438642',
  appId: '1:882971438642:web:685327d6bf61dce35d162b',
  measurementId: 'G-YTQQN048LD'
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/app/dashboard',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

data();
const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [mail, setMail] = useState('');
  const routing = useRoutes(routes);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setMail(user.email);
      window.localStorage.setItem('u', JSON.stringify(user));
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  if (isSignedIn) {
    if (mail !== 'hafidzaini@gmail.com' && mail.split('@').pop() !== 'zaini.co.id') {
      return (
        <Box
          sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center'
          }}
        >
          <Container maxWidth="sm">
            Email anda tidak bisa dipakai disini
          </Container>
        </Box>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    );
  }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </Container>
    </Box>

  );
};

export default App;
