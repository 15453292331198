import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  // Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  Users as UsersIcon,
  FileText as FileIcon,
  PieChart as PieIcon,
  Globe as GlobeIcon,
  Smartphone as SmartIcon,
  Activity as ActIcon,
  CheckCircle as CheckIcon,
  PhoneCall as PhoneIcon,
  Truck as TruckIcon,
  Shield as ShieldIcon
} from 'react-feather';
import NavItem from './NavItem';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/statcustcar',
    icon: BarChartIcon,
    title: 'Customer & Mobilnya'
  },
  {
    href: '/app/aplikasi',
    icon: SmartIcon,
    title: 'Aplikasi'
  },
  {
    href: '/app/websosmed',
    icon: GlobeIcon,
    title: 'Web Sosmed'
  },
  {
    href: '/app/ads',
    icon: ActIcon,
    title: 'Google Ads'
  },
  {
    href: '/app/kinerja',
    icon: PieIcon,
    title: 'Kinerja Bengkel'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/custcars',
    icon: TruckIcon,
    title: 'Mobil Customer'
  },
  {
    href: '/app/workorder',
    icon: FileIcon,
    title: 'Work Order'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Parts'
  },
  {
    href: '/app/followup',
    icon: PhoneIcon,
    title: 'Follow Up'
  },
  {
    href: '/app/verify',
    icon: CheckIcon,
    title: 'Verifikasi Pembayaran'
  },
  {
    href: '/app/approve',
    icon: ShieldIcon,
    title: 'Approval Pengeluaran'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  }
  /*
    {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }
  */
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [u, setU] = useState('');

  useEffect(() => {
    const dc = JSON.parse(window.localStorage.getItem('u')) || '';
    setU(dc);
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const user = {
    avatar: u.photoURL,
    jobTitle: u.email,
    name: u.displayName
  };
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 30,
            height: 30
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 2,
          p: 2
        }}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
