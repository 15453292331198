const Cust = () => {
  function cust() {
    const url = 'https://gaapi.zaini.co.id/customer';
    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((d) => {
        window.localStorage.setItem('cust', JSON.stringify(d));
      });
  }
  cust();
  return null;
};

export default Cust;
