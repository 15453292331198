import {
  Box,
  // Container,
  // Grid
} from '@material-ui/core';

const Kinerja = () => (
  <Box />
);

export default Kinerja;
