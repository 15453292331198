/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
  Box, Container, Card, Grid,
  CardContent,
  TextField,
  InputAdornment, SvgIcon, FormControlLabel, Radio, Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import CustomerListResults from 'src/components/customer/CustomerListResults';
import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
import CustomerCar from 'src/components/customer/CustomerCar';

const CustomerList = () => {
  const [c, setC] = useState([]);
  const [fc, setFc] = useState([]);
  const [rad, setRad] = useState('name');

  const [uid, setUid] = useState('');
  const [name, setName] = useState('');

  const newcust = () => setName('');

  const id = (event) => {
    setUid(event._id);
    setName(event.name);
  };

  const today = new Date().toISOString().slice(0, 10);
  useEffect(() => {
    const par = JSON.parse(window.localStorage.getItem('cust'));
    console.log(par);
    setC(par);
    setFc(par);
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    let result = c;
    result = result.filter((data) => data[rad].includes(String(value)));
    setFc(result);
    // console.log(rad);
    // console.log(value);
  };

  const handleChange = (event) => {
    setRad(event.target.value);
  };

  return (

    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <CustomerListToolbar newcust={newcust} />
        <Box style={{ marginTop: 20 }}>
          {name === '' ? <Box /> : <CustomerCar uid={uid} name={name} />}
        </Box>
        <Box sx={{ pt: 3 }}>
          <Card sx={{ my: 3 }}>
            <CardContent>
              <Box>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={3}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Pencarian Customer"
                      variant="outlined"
                      size="small"
                      onChange={(event) => handleSearch(event)}
                      helperText="Huruf besar kecil beda"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={5}
                    sm={6}
                    xl={6}
                    xs={12}
                  >
                    <div>
                      <FormControlLabel
                        control={(
                          <Radio
                            color="primary"
                            checked={rad === 'name'}
                            onChange={handleChange}
                            value="name"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'name' }}
                          />
)}
                        label={<Typography variant="body2" color="textSecondary">Nama</Typography>}
                      />
                      <FormControlLabel
                        control={(
                          <Radio
                            color="primary"
                            checked={rad === 'phone'}
                            onChange={handleChange}
                            value="phone"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'phone' }}
                          />
)}
                        label={<Typography variant="body2" color="textSecondary">No HP</Typography>}
                      />
                      <FormControlLabel
                        control={(
                          <Radio
                            color="primary"
                            checked={rad === 'email'}
                            onChange={handleChange}
                            value="email"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'email' }}
                          />
)}
                        label={<Typography variant="body2" color="textSecondary">Email</Typography>}
                      />
                      <FormControlLabel
                        control={(
                          <Radio
                            color="primary"
                            checked={rad === 'kab'}
                            onChange={handleChange}
                            value="kab"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'kab' }}
                          />
)}
                        label={<Typography variant="body2" color="textSecondary">Kabupaten / Kota</Typography>}
                      />
                    </div>

                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={4}
                    xl={4}
                    xs={12}
                  >
                    <form noValidate>
                      <TextField
                        id="date"
                        type="date"
                        label="Dari"
                        defaultValue="2021-08-01"
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>

                  </Grid>
                  <Grid
                    item
                    lg={2}
                    sm={4}
                    xl={4}
                    xs={12}
                  >
                    <form>
                      <TextField
                        id="date"
                        type="date"
                        label="Sampai"
                        defaultValue={today}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <CustomerListResults id={id} customers={fc} />
        </Box>
      </Container>
    </Box>

  );
};

export default CustomerList;
