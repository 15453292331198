import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Websosmed from 'src/pages/Websosmed';
import Aplikasi from 'src/pages/Aplikasi';
import Gads from 'src/pages/Gads';
import Kinerja from 'src/pages/Kinerja';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Workorder from 'src/pages/Workorder';
import Verify from 'src/pages/Verify';
import Custcars from 'src/pages/Custcars';
import Followup from 'src/pages/Followup';
import Statcustcar from 'src/pages/Statcustcar';
import Approval from 'src/pages/Approval';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'custcars', element: <Custcars /> },
      { path: 'followup', element: <Followup /> },
      { path: 'statcustcar', element: <Statcustcar /> },
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'workorder', element: <Workorder /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'websosmed', element: <Websosmed /> },
      { path: 'aplikasi', element: <Aplikasi /> },
      { path: 'ads', element: <Gads /> },
      { path: 'verify', element: <Verify /> },
      { path: 'approve', element: <Approval /> },
      { path: 'kinerja', element: <Kinerja /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
